<template>
  <panel title="Package Lists">
    <!-- use component -->
    <package-form-modal ref="PackageFormModal" @reloading="fetchPakage()" />
    <!-- end use component -->

    <data-table-local
      :columns="_columns"
      :items="items"
      @clickNew="$refs.PackageFormModal.show('')"
      @clickView="clickView"
      @clickEdit="clickEdit"
      @clickDelete="clickDelete"
      :viewAble="false"
    />
  </panel>
</template>
<script>
export default {
  created() {
    this.fetchPakage()
  },
  data() {
    return {
      items: [],
    }
  },
  components: {
    PackageFormModal: () => import("./components/index/PackageFormModal.vue"),
  },
  computed: {
    _columns() {
      return [
        {
          label: "Package Name",
          field: "name",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Detail",
          field: "detail",
          // tdClass: "text-right",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Credit Amount",
          field: "amount",
          tdClass: "text-right",
          thClass: "text-center",
          type: "number",
        },
        {
          label: "Price",
          field: "price",
          tdClass: "text-right",
          thClass: "text-center",
          type: "number",
        },
      ]
    },
  },
  methods: {
    clickView(data) {
      this.$refs.PackageFormModal.show(this.cp(data))
    },
    clickEdit(data) {
      this.$refs.PackageFormModal.show(this.cp(data))
    },
    async clickDelete(data) {
      try {
        const resp = await this.api.del(
          `/api/packages/${data.id}`,
          "Package",
          true
        )
        this.fetchPakage()
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPakage() {
      try {
        this.items = await this.api.get("/api/packages")
      } catch (error) {
        console.error(error)
        return ""
      }
    },
  },
}
</script>
